import {
	GET_COUPONS, GENERATE_COUPONS, DELETE_COUPON,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class Coupons {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	getCoupons(options) {
		const { method, endpoint } = GET_COUPONS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	generateCoupons(payload) {
		const { method, endpoint } = GENERATE_COUPONS;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	deleteCoupon(coupon) {
		const { method, endpoint } = DELETE_COUPON;
		return this.data[method](endpoint(coupon)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Coupons;
