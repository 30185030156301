<template>
	<form style="text-align: left">
		<div style="margin: 10px 0; display: flex; gap: 10px; align-items: center; justify-content: space-between;">
			<label
				style="font-weight: bold"
				for="swal_rate">
				{{ translate('discount_rate') }}
			</label>
			<input
				id="swal_rate"
				type="number"
				class="form-control"
				:min="min"
				:max="max"
				style="width: 100px;"
				required>
		</div>
		<div
			v-if="showPermanentField"
			style="margin: 10px 0; display: flex; align-items: center">
			<input
				id="swal_is_permanent"
				type="checkbox"
				class="form-control"
				style="width: unset;
				height: unset">
			<label
				style="font-weight: bold; margin: 0"
				for="swal_is_permanent">
				&nbsp;{{ translate('permanent_coupon') }}
				<i
					id="swal_is_permanent_info"
					class="fas fa-info-circle" />
			</label>
			<b-tooltip target="swal_is_permanent_info">
				{{ translate('permanent_coupon_info') }}
			</b-tooltip>
		</div>
	</form>
</template>

<script>
import { Coupons } from '@/translations';

export default {
	name: 'GenerateCouponForm',
	messages: [Coupons],
	props: {
		min: {
			type: Number,
			required: true,
		},
		max: {
			type: Number,
			required: true,
		},
		showPermanentField: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
